<template>
  <div>
    <d-body-top-bar :title="$t('admin.clubs.list_clubs')">
      <div slot="tree">
        <label class="inactive-tree">
          {{ $t('admin.clubs.list') }}
        </label>
      </div>
    </d-body-top-bar>
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12 all-bookings">
          <b-card class="all-bookings-card">
            <b-row class="row-style" no-gutters>
              <b-col class="mt-2 ml-3">

              </b-col>
              <b-col class="mt-3 text-right mr-2" cols="4">
                <d-button
                  :text="'admin.clubs.actions.create'"
                  :class="'ml-2 d-btn btn d-btn-primary'"
                  icon="fa fa-plus mr-1"
                  @on:button-click="addClub"
                />
              </b-col>
              <b-col class="mt-3 mr-3">
                <div class="has-search">
                  <span class="fa fa-search form-control-feedback"></span>
                  <input
                    type="text"
                    class="form-control"
                    v-model="filter"
                    @keyup="manualFilter"
                    :placeholder="$t('components.doinsport.table.search')"
                  >
                </div>
              </b-col>
            </b-row>
            <d-table
              :tablefields="tableFields"
              :items="items"
              :is-busy="isBusy"
              :totalRows="totalRows"
              :per-page="itemsPerPage"
              :tr-cursor-pointer="true"
              @row-clicked="onRowClicked"
              @current-page:update="onCurrentPageUpdate"
              @per-page:update="onPerPageUpdate"
              @filter:from-server="search"
            >
            </d-table>
          </b-card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {getAllClub} from "@api/services/club/club.api";

const moment = require('moment');

export default {
  data: () => ({
    visible: false,
    passed: false,
    updatedTab: false,
    isBusy: true,
    commingSoon: true,
    canceled: false,
    items: [],
    itemsPerPage: 30,
    currentPage: 1,
    totalRows: 1,
    hide: true,
    show: true,
    isCanceled: false,
    operator: 'after',
    date: moment().format('YYYY-MM-DD'),
    bookingsList: [],
    idClub: null,
    filter: ''
  }),
  created() {
    this.loadClubs();
  },
  computed: {
    tableFields() {
      return [
        {key: "name", label: this.$t("views.bookings.index.bookingName"), sortable: true},
        {key: "phone", label: this.$t("views.bookings.index.reservedBy"), sortable: true},
        {key: "city", label: this.$t("views.bookings.index.reservedBy"), sortable: true},
        {key: "city", label: this.$t("views.bookings.index.reservedBy"), sortable: true},
        {key: "zipCode", label: this.$t("views.bookings.index.reservationDate"), sortable: true},
      ];
    }
  },
  methods: {
    onRowClicked(scope) {
      localStorage.setItem('selectedClub',scope.id);
      this.$router.push('clubs/detail/' + scope.id);
    },
    loadClubs() {
      this.isBusy = true;
      this.items = [];
      getAllClub(this.currentPage, this.itemsPerPage, this.filter)
        .then(resp => {
          if (resp.data) {
            resp.data['hydra:member'].forEach(item => {
              this.items.push({
                iri: item['@id'],
                name: item.name,
                city: item.city,
                zipCode: item.zipCode,
                phone: item.fixPhoneNumber,
                email: item.email,
                id: item.id
              });
            });
            this.isBusy = false;
            this.totalRows = resp.data['hydra:totalItems'];
          }
        });
    },
    onCurrentPageUpdate(page) {
      this.currentPage = page;
      this.loadClubs();
    },
    onPerPageUpdate(itemsPerPage) {
      this.itemsPerPage = itemsPerPage;
      this.loadClubs();
    },
    search() {
      this.loadClubs();
    },
    addClub() {
      this.$router.push('/clubs/new');
    },
    manualFilter() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.search();
      }, 400);
    },
  }
};
</script>
<style scoped>
.all-bookings-card .card-body {
  -ms-flex: 1 1 auto;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  min-height: 263px;
  padding: 1rem;
}

.all-bookings .card {
  box-shadow: 0px 10px 16px #E2E1E6;
  border-radius: 8px;
}
</style>
